import { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../utils/AuthContext';
import { useNavigate } from 'react-router-dom';

const NavbarEP = () => {
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const { dispatch } = useContext(AuthContext);

    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        setScrolled(offset > 0); // Sets `scrolled` to true if user has scrolled down
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLogout = () => {
        sessionStorage.removeItem("userLogin");
        dispatch({ type: "LOGOUT" });
        navigate("/");
    };

    return (
        <nav id='navbar' style={{ ...styles.navbar, backgroundColor: scrolled ? '#ffffff' : 'transparent', boxShadow: scrolled ? '0 2px 4px rgba(0, 0, 0, 0.1)' : '' }}>
            <div style={styles.container}>
                <div style={styles.brand} onClick={() => navigate('/')}>
                    GCCpark
                </div>
                <div style={styles.linksContainer}>
                    <div style={styles.navLink} onClick={() => navigate('/')}>Home</div>
                    <div style={styles.navLink} onClick={() => navigate('/about')}>About</div>
                </div>

                {user && !user?.isAdmin && !user?.data?.isAdmin && (
                    <div style={styles.authLinks}>
                        <div style={styles.userLink}>{user?.email ?? user?.data?.email}</div>
                        <div style={styles.navLink} onClick={handleLogout}>Log out</div>
                    </div>
                )}

                {!user && (
                    <div style={styles.authLinks}>
                        <div style={styles.navLink} onClick={() => navigate('/auth/login')}>
                            Sign in / Sign up
                        </div>
                    </div>
                )}

                {user && (user?.isAdmin || user?.data?.isAdmin) && (
                    <div style={styles.authLinks}>
                        <div style={styles.userLink}>
                            Admin: {user?.email ?? user?.data?.email}
                        </div>
                        <div style={styles.navLink} onClick={handleLogout}>Log out</div>
                    </div>
                )}
            </div>
        </nav>
    );
};

const styles = {
    navbar: {
        position: 'fixed',
        top: 0,
        width: '100%',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        padding: '10px 20px',
        zIndex: 1000,
        transition: 'background-color 0.3s ease', // Smooth transition for background color
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '1200px',
        margin: '0 auto',
    },
    brand: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: '#333333',
        cursor: 'pointer',
    },
    linksContainer: {
        display: 'flex',
        gap: '15px',
    },
    navLink: {
        fontSize: '1rem',
        padding: '8px 12px',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        color: '#333333',
    },
    navLinkHover: {
        backgroundColor: '#f0f0f0',
    },
    authLinks: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    userLink: {
        fontSize: '1rem',
        color: '#6200ea',
    },
};

export default NavbarEP;
