import React from "react";
import Search from "../../components/search/Search";
import RentOut from "../../components/rent-out/rent-out"



const Home = () => {
    return (
        <div>
            <Search />
            <RentOut />
        </div>
    )
}

export default Home;