import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import "./search.css";

const Search = () => {
    const navigate = useNavigate();
    const [destination, setDestination] = useState("");
    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");
    const [arrivingDate, setArrivingDate] = useState("");
    const [leavingDate, setLeavingDate] = useState("");
    const [arrivingTime, setArrivingTime] = useState("");
    const [leavingTime, setLeavingTime] = useState("");
    const inputRef = useRef();

    const handleSearch = async () => {
        const searchText = inputRef.current.value;

        if (!searchText) return;

        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${searchText}&format=json&limit=1`);
            const data = await response.json();

            if (data.length > 0) {
                const { lat, lon: lng, display_name: destination } = data[0];
                setLat(lat);
                setLng(lng);
                setDestination(destination);

                navigate("/carparks", {
                    state: { lat, lng, destination, arrivingDate, leavingDate, arrivingTime, leavingTime }
                });
            } else {
                alert("No results found. Please try another search term.");
            }
        } catch (error) {
            console.error("Error fetching location:", error);
            alert("An error occurred while searching for the location.");
        }
    };

    return (
        <Container fluid className="px-0">
            <div className="search-session">
                <div className="search-details">
                    <h3 className="text-center mb-4">New Option of Parking - GCCpark</h3>
                    <p className="text-center text-white" >Book now! Pay later</p>
                    <Form>
                        <Row className="align-items-center justify-content-center">
                            <Col md={6}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    </InputGroup.Text>
                                    <Form.Control
                                        ref={inputRef}
                                        type="text"
                                        placeholder="Search address or place name"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={3}>
                                <Form.Group controlId="arrivingDate">
                                    <Form.Label>Arriving Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={arrivingDate}
                                        onChange={(e) => setArrivingDate(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="leavingDate">
                                    <Form.Label>Leaving Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={leavingDate}
                                        onChange={(e) => setLeavingDate(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="arrivingTime">
                                    <Form.Label>Arriving Time</Form.Label>
                                    <Form.Control
                                        type="time"
                                        value={arrivingTime}
                                        onChange={(e) => setArrivingTime(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="leavingTime">
                                    <Form.Label>Leaving Time</Form.Label>
                                    <Form.Control
                                        type="time"
                                        value={leavingTime}
                                        onChange={(e) => setLeavingTime(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="text-center">
                            <Button variant="primary" style={{ fontWeight: 'bold', backgroundColor: '#00B8A9', border: 0 }} onClick={handleSearch}>
                                Find Parking
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </Container>
    );
};

export default Search;
