import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import RentOutBg from "../../images/rent-out-bg.webp";

const ParkingAdSection = () => {
    return (
        <Container fluid className="bg-light text-center" style={{ padding: '80px 0', background: 'linear-gradient(135deg, #F2E5D5, #FFFFFF)' }}>
            <Row className="align-items-center justify-content-center" style={{ padding: '0 30px', borderRadius: '10px' }}>
                <Col md={6}>
                    <h1 style={{ color: '#333333', fontWeight: 'bold' }}>Rent out your parking or EV charging space</h1>
                    <p style={{ fontSize: '18px', color: '#B3B3B3' }}>
                        Make easy tax-free money by renting out your parking or EV charging space. It’s free to list and only takes a few minutes to get up and running.
                    </p>
                    <Button variant="success" size="lg" style={{ fontWeight: 'bold', backgroundColor: '#00B8A9', border: 0 }}>Learn how to earn today</Button>
                </Col>
                <Col md={6} className="d-none d-md-block">
                    <img src={RentOutBg} alt="Parking spaces in front of houses" className="img-fluid" style={{ borderRadius: '10px' }} />
                </Col>
            </Row>
        </Container>
    );
};

export default ParkingAdSection;
