import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Footer = () => {
  return (
    <footer id='footer'>
      <Container fluid>
        <Row>
          <Col style={{ marginBottom: '80px', textAlign: 'center' }}>&copy; GCCpark 2024</Col>
        </Row>
      </Container>
    </footer>
  )
}
export default Footer